import { SvgIcon } from "@mui/material";
import { IconProps } from "./_interface";

const SvgCircleCancelIcon = (props: IconProps) => (
  <SvgIcon {...props} color="inherit" viewBox="0 0 40 40">
    <path d="M20 0C8.97156 0 0 8.97156 0 20C0 31.0284 8.97156 40 20 40C31.0284 40 40 31.0284 40 20C40 8.97156 31.0284 0 20 0Z" fill="currentColor"/>
    <path d="M27.3595 25.0026C28.0111 25.6545 28.0111 26.7077 27.3595 27.3595C27.0345 27.6845 26.6079 27.8478 26.1809 27.8478C25.7543 27.8478 25.3277 27.6845 25.0026 27.3595L19.9944 22.351L14.9862 27.3595C14.6611 27.6845 14.2345 27.8478 13.8079 27.8478C13.3809 27.8478 12.9543 27.6845 12.6293 27.3595C11.9777 26.7077 11.9777 25.6545 12.6293 25.0026L17.6378 19.9944L12.6293 14.9862C11.9777 14.3343 11.9777 13.2811 12.6293 12.6293C13.2811 11.9777 14.3343 11.9777 14.9862 12.6293L19.9944 17.6378L25.0026 12.6293C25.6545 11.9777 26.7077 11.9777 27.3595 12.6293C28.0111 13.2811 28.0111 14.3343 27.3595 14.9862L22.351 19.9944L27.3595 25.0026Z" fill="white"/>
  </SvgIcon>
);

export default SvgCircleCancelIcon;
