import { Box, Dialog, Zoom, CircularProgress } from "@mui/material";
import Typography from "@atoms/Typography";
import Button from "@atoms/Button";
import { useSelector } from "react-redux";
import { useAppDispatch } from "@redux/store";
import { IPopupAlert, selectPopupAlert } from "@redux/slices/popupAlert";
import { setCloseAlert } from "@redux/actions";
import CircleCancelIcon from "@icons/CircleCancel";
import CircleCheckIcon from "@icons/CircleCheck";
import { Root, IconStyle } from "./styles";

const PopupAlert = () => {
  const dispatch = useAppDispatch();
  const popupAlert: IPopupAlert = useSelector(selectPopupAlert);
  const { message, variant = "success", buttonLabel, onClose } = popupAlert;

  const loading = variant === "loading";

  const handleClose = () => {
    if (onClose) {
      onClose();
    }

    dispatch(setCloseAlert());
  };

  const Icon = {
    success: CircleCheckIcon,
    failed: CircleCancelIcon,
    loading: CircularProgress,
  }[variant];

  return (
    <Dialog open={Boolean(message)} TransitionComponent={Zoom} css={Root}>
      {!loading ? <Icon css={IconStyle(variant)} /> : <CircularProgress size={80} />}
      <Box py={4} textAlign="center">
        <Typography variant="h5" weight="medium" children={message} />
      </Box>
      <Button disabled={loading} children={buttonLabel} onClick={handleClose} />
    </Dialog>
  );
};

export default PopupAlert;
