import { makeStyles } from "@mui/styles";
import color from "../../../definitions/mui/color";
import customTheme from "../../../definitions/mui/custom";
import { IButtonStyle } from "./interface";

const useStyles = makeStyles(() => ({
  disabled: () => {
    const disabledStyles: any = {};

    Object.assign(disabledStyles, {
      backgroundColor: color.inactive.primary + "!important",
      color: color.inactive.secondary,
    });

    return disabledStyles;
  },
  preload: (props: IButtonStyle) => {
    const { size } = props;
    const preloadStyle: any = {};

    Object.assign(preloadStyle, {
      color: color.inactive.secondary,
      height: "18px" + "!important",
      width: "18px" + "!important",
      ...customTheme.typography["button-" + size],
    });

    return preloadStyle;
  },
  root: (props: IButtonStyle) => {
    const { size, width, variant } = props;

    const rootStyles = {
      boxShadow: `2px 10px 20px 0px #2E434D1A`,
      borderRadius: "24px",
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
      backgroundColor: color.primary.main,
      color: color.white,
      width,
      "&:hover": {
        backgroundColor: color.primary.mid,
      },
      ...customTheme.typography["button-" + size],
    };

    if (variant === "secondary") {
      Object.assign(rootStyles, {
        backgroundColor: color.white,
        border: `1px solid ${color.primary.main}`,
        color: color.primary.main,
        "&:hover": {
          backgroundColor: color.primary.lighter,
        },
      });
    }

    return rootStyles;
  },
}));

export default useStyles;
