import classnames from "classnames";
import { TextProps } from "./interface";
import { colorMapper } from "@definitions/mui/color";
import styles from "./typography.module.css";

const Typography: React.FC<TextProps> = (props) => {
  const {
    children,
    color = "general-main",
    variant = "subtitle2",
    weight = "normal",
    inline = false,
    font = "open-sans",
  } = props;

  const inlineClass = inline ? "block" : "";

  const _color = colorMapper(color);

  return (
    <span
      className={classnames(
        styles.root,
        styles[variant],
        styles[weight],
        styles[font],
        inlineClass,
        props.className,
      )}
      style={{ ...{ color: _color }, ...props.style }}
    // {...props}
    >
      {children}
    </span>
  );
};

export default Typography;
