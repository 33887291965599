import { SvgIcon } from "@mui/material";
import { IconProps } from "./_interface";

const SvgCircleCheckIcon = (props: IconProps) => (
  <SvgIcon {...props} color="inherit" viewBox="0 0 40 40">
    <circle cx="20" cy="20" fill="currentColor" r="20"/>
    <path clipRule="evenodd" d="M31.9479 9.26072C31.7828 9.13642 31.5949 9.04598 31.3948 8.99458C31.1947 8.94319 30.9864 8.93186 30.7819 8.96125C30.5774 8.99064 30.3807 9.06017 30.2032 9.16584C30.0257 9.27151 29.8708 9.41123 29.7475 9.57697L16.6848 27.0708L10.1057 20.4631C9.96025 20.3163 9.78712 20.1998 9.59636 20.1203C9.40559 20.0408 9.20097 19.9998 8.99429 19.9998C8.78762 19.9998 8.583 20.0408 8.39223 20.1203C8.20146 20.1998 8.02834 20.3163 7.88284 20.4631C7.58762 20.7596 7.42187 21.161 7.42188 21.5795C7.42188 21.9979 7.58762 22.3993 7.88284 22.6958L15.7436 30.5914C15.8892 30.738 16.0623 30.8544 16.2531 30.9338C16.4438 31.0132 16.6484 31.054 16.855 31.0539C16.8922 31.0539 16.9295 31.0527 16.9667 31.0503C17.1916 31.0341 17.4104 30.9695 17.6082 30.861C17.8059 30.7525 17.9778 30.6026 18.1123 30.4216L32.2627 11.4727C32.5132 11.1372 32.6207 10.7162 32.5617 10.3017C32.5027 9.88723 32.282 9.51295 31.9479 9.26072Z" fill="white" fillRule="evenodd"/>
  </SvgIcon>
);

export default SvgCircleCheckIcon;
