import { FC } from "react";
import MuiButton from "@mui/material/Button";
import { loading as preload } from "../../../config/images";
import { ButtonProps } from "./interface";
import useStyles from "./styles";

const Button: FC<ButtonProps> = ({
  children,
  disabled,
  loading,
  startIcon,
  endIcon,
  size,
  variant,
  width,
  ...rest
}) => {
  const classes = useStyles({
    size,
    width,
    variant,
  });

  const _renderStartIcon = () => {
    if (loading) {
      return <img src={preload} width={16} height={16} />;
    }
    return startIcon;
  };

  return (
    <MuiButton
      classes={{
        root: classes["root"],
        disabled: classes["disabled"],
      }}
      disabled={disabled || loading}
      endIcon={endIcon}
      size={size}
      startIcon={_renderStartIcon()}
      {...rest}
    >
      {!loading ? children : "Menunggu..."}
    </MuiButton>
  );
};

export default Button;
