import { css } from "@emotion/react";
import color from "@definitions/mui/color";

export const Root = css({
  "& .MuiDialog-paper": {
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: 400,
    padding: 32,
    width: 400,
  },
});

export const IconStyle = (variant: string) => {
  const iconColor = {
    failed: color.primary.main,
    success: color.green.main,
  }[variant];

  return css({
    color: iconColor,
    height: 80,
    width: 80,
  });
};
